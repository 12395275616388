/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import { useTranslation } from 'react-i18next';

// UI lib comoponents

// Local UI components

// assets
import TheDotLogo from '../../../images/Programs/the-dot/hero-logo.svg';
import TheDotBg from '../../../images/Programs/the-dot/hero-bg.svg';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function Hero() {
  /* ******************************** CONSTANTS ******************************* */
  /* ************************ ESSENTIAL LOCAL VARIABLES *********************** */
  /* ********************************** HOOKS ********************************* */

  // Location
  const { t } = useTranslation();
  const { title } = t('hero', { returnObjects: true });
  /* ***************************** LOCAL VARIABLES **************************** */
  /* ******************************** CALLBACKS ******************************* */
  /* ***************************** RENDER HELPERS ***************************** */
  /* ******************************** RENDERING ******************************* */

  return (
    <section className="the-dot-hero scroll-reveal">
      <img
        src={TheDotBg}
        alt=""
        className="the-dot-hero__bg"
        aria-hidden="true"
      />
      <img src={TheDotLogo} alt="logo the dot" className="the-dot-hero__logo" />
      <h1 className="the-dot-hero__title">{title}</h1>
    </section>
  );
}

Hero.propTypes = {};

Hero.defaultProps = {};

export default Hero;
