/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import * as React from 'react';
import { graphql } from 'gatsby';

// Lib UI components

// Local UI components
import PageLayout from '../shared/PageLayout';
import Seo from '../shared/Seo';
import Hero from '../page-components/ProgramTheDot/Hero';
import Content from '../page-components/ProgramTheDot/Content';
import VisitationSection from '../shared/UIKit/VisitationSection';

// Style
import '../page-styles/ProgramTheDot.scss';

/* -------------------------------------------------------------------------- */
/*                                    Page                                    */
/* -------------------------------------------------------------------------- */

function ProgramTheDot() {
  /* ********************************** HOOKS ********************************* */

  // Localization

  /* ******************************** RENDERING ******************************* */
  return (
    <PageLayout>
      <Seo title="Program the dot" />
      <section className="program-the-dot">
        <Hero />
        <Content />
      </section>
      <VisitationSection namePage="The Dot" navigation="https://thedot.tn/" />
    </PageLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["ProgramTheDot", "Common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default ProgramTheDot;
