/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import { useTranslation } from 'react-i18next';

// UI lib comoponents
import { Container, Row, Col } from 'react-grid-system';

// Local UI components
// @TODO: Import other locally-made dependencies here
import Image from '../../../shared/Image';
import SubTitle from '../../../shared/UIKit/SubTitle';

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function Content() {
  /* ******************************** CONSTANTS ******************************* */
  /* ************************ ESSENTIAL LOCAL VARIABLES *********************** */

  // localisation
  const { t } = useTranslation();
  const { title, paragraphs } = t('content', { returnObjects: true });

  /* ********************************** HOOKS ********************************* */
  /* ***************************** LOCAL VARIABLES **************************** */
  /* ******************************** CALLBACKS ******************************* */
  /* ***************************** RENDER HELPERS ***************************** */
  function Paragraphs() {
    return paragraphs.map(({ id, text }) => (
      <p key={id} className="the-dot-content__paragraph">
        {text}
      </p>
    ));
  }
  /* ******************************** RENDERING ******************************* */

  return (
    <Container className="the-dot-content scroll-reveal">
      <SubTitle>{title}</SubTitle>
      <Row>
        <Col lg={6}>
          <Paragraphs />
        </Col>
        <Col lg={6}>
          <div className="the-dot-content__img">
            <Image src="/the-dot/content-img.jpg" alt="" aria-hidden="true" />
          </div>
        </Col>
      </Row>
    </Container>
  );
}

Content.propTypes = {};

Content.defaultProps = {};

export default Content;
